import { Form, Formik } from "formik";
import {
  checkUserExistsPhoneLogin,
  login,
  loginWithPhone,
  sendOTPForLogin,
  verifyOTPForLogin,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import {
  loginUser,
  refreshCaptchaToken,
} from "../../store/features/Auth/AuthSlice";
import { updateDanaPayCountries } from "../../store/features/Transfer/TransferSlice";
import { getTransferCountries } from "../../store/features/Transfer/Transfer";
import { extractErrorMessage } from "../../helpers";
import { buildString, handleRedirection, isEmpty } from "../../utilities/help";
import InputField from "../forms/InputField";
import { Alert } from "@material-ui/lab";
import OtpModal from "./OtpModal";
import { useEffect, useRef, useState } from "react";
import CountryPicker from "../CountryPicker";
import FormErrorText from "../FormErrorText";

const LoginWithPhone = (props: any) => {
  const {
    t,
    showErrorMessage,
    captchaToken,
    dispatch,
    setLoggedUserCountry,
    history,
    toast,
    handleHeapAndCustomerioEvents,
    refreshCaptcha,
    errorMessage,
    yup,
    setRegistrationData,
    setisUserRejected,
  } = props;

  const [isOtpModalOpen, setisOtpModalOpen] = useState(false);
  const [otp, setotp] = useState("");
  const [isOtpValid, setisOtpValid] = useState(false);
  const [country_code, setcountry_code] = useState("");
  const [phone_number, setphone_number] = useState("");
  const form: any = useRef(null);

  const validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .min(8, "the_phone_number_must_be_at_least_8_characters")
      .max(15, "the_phone_number_may_not_be_greater_than_12_characters")
      .matches(/^(\d){7,15}$/, "validPhone")
      .required("PhoneNumberError"),
    pincode: isOtpValid
      ? yup
          .string()
          .matches(/^(\d{4}|\d{6})$/, "pincode_invalid")
          .required("pincode_error")
      : yup.string().matches(/^(\d{4}|\d{6})$/, "pincode_invalid"),
    country_code: yup.string().required("CE"),
  });

  const handleOtpSubmit = () => {
    verifyOTPForLogin({
      country_code: parseInt(country_code),
      phone_number: phone_number,
      verification_code: otp,
    })
      .then(() => {
        setisOtpValid(true);
      })
      .catch((err: any) => {
        if (err.data && err.data.message) {
          toast.error(err.data.message);
        }
        Sentry.captureException(err);
      })
      .finally(() => {
        setisOtpModalOpen(false);
        setotp("");
      });
  };

  useEffect(() => {
    form.current && form.current.validateForm();
  }, [isOtpValid]);

  const isUserRejected = (result: any) => {
    const verificationResult = result?.user?.verification_result;
    const userRejected = result?.user?.is_rejected;
    const userActive = result?.user?.is_active;
    if (userActive) {
      setisUserRejected(false);
      return true;
    } else if (
      (verificationResult == "RED-FINAL" && !userActive) ||
      (userRejected == true && !userActive)
    ) {
      setisUserRejected(true);
      return undefined;
    } else {
      setisUserRejected(false);
      return true;
    }
  };

  const handleUserTypeSelection = (result: any) => {
    try {
      const userIsResumingAfterAccountSelection = !isEmpty(
        localStorage.getItem("acctype")
      );
      if (!userIsResumingAfterAccountSelection) {
        if (result.user.is_individual) {
          localStorage.setItem("acctype", "Individual");
        } else {
          localStorage.setItem("acctype", "Business");
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // get countries in case its missing
  useEffect(() => {
    try {
      getTransferCountries().then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          dispatch(updateDanaPayCountries(res.data));
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <>
      <Formik
        validateOnChange
        innerRef={form}
        initialValues={{
          phoneNumber: "",
          pincode: "",
          captcha: "",
          country_code: "",
        }}
        onSubmit={(data: any, { setSubmitting }) => {
          try {
            setSubmitting(true);
            showErrorMessage([]);
            if (!isOtpValid) {
              checkUserExistsPhoneLogin({
                country_code: parseInt(data?.country_code),
                phoneNumber: data.phoneNumber,
              })
                .then(() => {
                  setcountry_code(data.country_code);
                  setphone_number(data.phoneNumber);
                  sendOTPForLogin({
                    country_code: parseInt(data.country_code),
                    phone_number: data.phoneNumber,
                  }).then(() => {
                    setSubmitting(false);
                    setisOtpModalOpen(true);
                  });
                })
                .catch((error: any) => {
                  toast.error(t("phone_login_not_existing"));
                  Sentry.captureException(error);
                })
                .finally(() => setSubmitting(false));
            } else {
              // login user data
              data.captcha = captchaToken;
              captchaToken !== null &&
                loginWithPhone({
                  country_code: parseInt(data.country_code),
                  phone_number: data.phoneNumber,
                  pincode: data.pincode,
                  heapio_user_id: window.heap.userId,
                })
                  .then((result: any) => {
                    localStorage.removeItem("depositattempts");
                    localStorage.removeItem("maketransferattempts");

                    if (isUserRejected(result) == undefined) {
                      setSubmitting(false);
                      return;
                    }

                    handleHeapAndCustomerioEvents(result, data);
                    setRegistrationData(result.user);
                    handleUserTypeSelection(result);

                    setSubmitting(false);
                    // if the response hasa token [user exists]
                    if (result.access_token) {
                      // store the user data in redux store
                      localStorage.setItem("user:key", result.access_token);
                      setLoggedUserCountry(result.user);
                      dispatch(loginUser(result));

                      handleRedirection(result?.user, history);
                    } else {
                      toast.warning(JSON.stringify(result));
                    }
                  })
                  .catch((error) => {
                    Sentry.captureException(error);
                    setSubmitting(false);
                    dispatch(
                      refreshCaptchaToken(
                        refreshCaptcha === true ? false : true
                      )
                    );
                    const err = extractErrorMessage(error);
                    const errorArray = err.map((errorText: string) =>
                      buildString(errorText?.toLowerCase())
                    );
                    showErrorMessage(errorArray);
                  })
                  .finally(() => setSubmitting(false));
            }
          } catch (error: any) {
            Sentry.captureException(error);
            showErrorMessage((err: any) => [...err, error.message]);
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
          isValid,
          setFieldTouched,
          dirty,
        }) => (
          <Form>
            <div className="mb-3 outerfieldBox">
              <small className="text-gray-600 form-label">
                {t("Phone_Number")}
              </small>
              <CountryPicker
                setCountry={(text: any) => {
                  handleChange("country_code")(text.mobileCode);
                  setFieldTouched("country_code", true, false);
                }}
                cash_Out_Method_Name={""}
                country={null}
                onChange={(text: string) => {
                  handleChange("phoneNumber")(text);
                  setFieldTouched("phoneNumber", true, false);
                }}
                value={values.phoneNumber}
                withPhoneInput={true}
                country_selected={true}
                setSelectedCountry={(e: any) => {}}
              />
              {errors.phoneNumber && (
                <FormErrorText errorMessage={errors.phoneNumber} />
              )}
              {errors.country_code && (
                <FormErrorText errorMessage={errors.country_code} />
              )}
              {/* <InputField
                name="phoneNumber"
                handleChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Phone_Number")}
                value={values.phoneNumber}
                label={t("Phone_Number")}
                error={t(`${errors.phoneNumber}`)}
                touched={touched.phoneNumber}
              /> */}
            </div>
            <InputField
              extraClasses="mb-3 customInputStyle"
              name="pincode"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("pincode")}
              disabled={!isOtpValid}
              value={values.pincode}
              label={t("pincode")}
              error={t(`${errors.pincode}`)}
              touched={touched.pincode}
            />

            <div
              className="flex flex-row items-center justify-between mt-10"
              data-login="login"
            >
              <button
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
                className="px-10 rounded-lg btn"
              >
                <span className="text-white capitalize">
                  {isOtpValid ? t("login") : t("phone_verify_header")}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <OtpModal
        open={isOtpModalOpen}
        onClose={() => {
          setisOtpModalOpen(false);
          setisOtpValid(false);
        }}
        t={t}
        otp={otp}
        setotp={setotp}
        onSubmit={handleOtpSubmit}
      />
      {errorMessage.length > 0 && (
        <div className="mb-4">
          <Alert severity="error">
            {errorMessage.map((errorText: string, index: number) => (
              <p>
                <small key={index}>{t(errorText)}</small>
              </p>
            ))}
          </Alert>
        </div>
      )}
    </>
  );
};

export default LoginWithPhone;
