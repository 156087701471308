import { Form, Formik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import InputField from "../forms/InputField";
import CountryPicker from "../CountryPicker";
import * as Sentry from "@sentry/browser";
import FormErrorText from "../FormErrorText";
import { Alert } from "@material-ui/lab";
import { buildString, isEmpty } from "../../utilities/help";
import { saveRegistrationProgress } from "../../store/features/Auth/Auth";
import GoogleAutoCompleteField from "../forms/GoogleAutoCompleteField";
import OtpModal from "../Login/OtpModal";

import {
  get_phone_otp,
  verify_phone_otp,
} from "../../store/features/Auth/Auth";
import { toast } from "material-react-toastify";

const PersonalInfoScreen = (props: any) => {
  const {
    t,
    user,
    signUpData,
    phone,
    country,
    setErrorsState,
    typeOfUser,
    dispatch,
    setActiveStep,
    setProcessing,
    uservalue,
    Yup,
    setprogressBarData,
    setCountry,
    setIsIndividualUser,
    setisUserComingBack,
    setCountryName,
    setPhone,
    errorsState,
    setSelectedCountryReset,
    handleBack,
    processing,
  } = props;

  // clean the div left by google autocomplete
  useEffect(() => {
    try {
      return () => {
        let elements: any = document.getElementsByClassName("pac-container");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  // contact/personal information schema
  const validationSchema = useMemo(() => {
    if (uservalue == "individual") {
      return Yup.object().shape({
        lastname: Yup.string().required("LNR"),
        firstname: Yup.string().required("FNR"),
        phone_number: Yup.string()
          .min(8, "the_phone_number_must_be_at_least_8_characters")
          .max(12, "the_phone_number_may_not_be_greater_than_12_characters")
          .required("PhoneNumberError"),
        country_code: Yup.string().required("CE"),
        city: Yup.string().required("CIE"),
        address_information: Yup.string().required("AIE"),
        post_code: Yup.string().test(
          "yourTestCondition",
          "post_code_error",
          function (value: any, context: any) {
            return (
              (this.parent.isPostCodeRequired == "true" && !isEmpty(value)) ||
              this.parent.isPostCodeRequired == "false"
            );
          }
        ),
        isPostCodeRequired: Yup.string(),
      });
    } else {
      return Yup.object().shape({
        lastname: Yup.string().required("LNR"),
        firstname: Yup.string().required("FNR"),
        phone_number: Yup.string()
          .min(8, "the_phone_number_must_be_at_least_8_characters")
          .max(12, "the_phone_number_may_not_be_greater_than_12_characters")
          .required("PhoneNumberError"),
        country_code: Yup.string().required("CE"),
        post_code: Yup.string().test(
          "yourTestCondition",
          "post_code_error",
          function (value: any, context: any) {
            return (
              (this.parent.isPostCodeRequired == "true" && !isEmpty(value)) ||
              this.parent.isPostCodeRequired == "false"
            );
          }
        ),
        isPostCodeRequired: Yup.string(),
      });
    }
  }, [uservalue]);

  const handleHeapAndProgressBar = (usertype: any, json: any) => {
    // heap events
    setprogressBarData({
      progress: usertype.value === "individual" ? 40 : 50,
      title:
        usertype.value === "individual"
          ? "revenue_information"
          : "Company_Information",
    });
    if (usertype.value === "individual") {
      window.heap.track("individual identity information set", {
        ...json,
      });
      window.heap.track("Has logged in as an individual", {
        email: user?.email,
      });
    } else {
      window.heap.track("company identity information set", {
        ...json,
      });
      window.heap.track("Has logged in as a company", {
        email: user?.email,
      });
    }
  };

  // OTP
  const [otp, setotp] = useState("");
  const [isOtpModalOpen, setisOtpModalOpen] = useState(false);
  const [isOtpSent, setisOtpSent] = useState(false);

  const sendOTP = (values: any, isResend = false) => {
    try {
      !isResend && setProcessing(true);
      const { country_code, phone_number } = values;
      get_phone_otp({
        country_code,
        phone_number,
        channel: values.channel ? values.channel.toLowerCase() : "sms",
      })
        .then(() => {
          setisOtpSent(true);
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(
            error?.data?.error ||
              error?.data?.message ||
              t("otp_failed_default_error")
          );
        })
        .finally(() => setProcessing(false));
    } catch (error) {
      setProcessing(false);
      Sentry.captureException(error);
    }
  };

  const validateOTP = (handleSubmit: any, values: any, setSubmitting: any) => {
    try {
      setProcessing(true);
      const { country_code, phone_number } = values;
      verify_phone_otp({
        phone_number: phone_number,
        country_code: country_code,
        verification_code: otp,
      })
        .then(() => handleSubmit())
        .catch((error: any) => {
          setProcessing(false);
          toast.error(error?.data?.error || t("otp_failed_default_error"));
        })
        .finally(() => {
          setotp("");
        });
    } catch (error) {
      toast.error(error);
      setProcessing(false);
      Sentry.captureException(error);
    }
  };

  const onSubmit = (data: any, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
      setProcessing(true);
      setErrorsState([]);
      const json = {
        email: user?.email,
        phone_number: data.phone_number || phone,
        country_code: parseInt(data.country_code) || parseInt(country),
        first_name: data.firstname,
        last_name: data.lastname,
        address_line: data.address_information,
        city: data?.city,
        post_code: data?.post_code,
        countryName: data?.countryName,
      };
      const usertype: any = typeOfUser.find((itm: any) => itm.active);

      setIsIndividualUser(usertype.value === "individual");

      setisUserComingBack(false);

      // add company to user profile if it exists
      if (uservalue !== "individual") {
        let payload: any = { ...json };
        delete payload.address_line;
        delete payload.city;
        delete payload.post_code;

        if (user?.company !== undefined && user?.company !== null) {
          payload.company = user?.company;
        }
        dispatch(setSignUpData({ ...signUpData, ...payload }));
        saveRegistrationProgress({
          ...signUpData,
          ...payload,
          usertype: "business",
          step: 1,
        })
          .then(() => window.localStorage.removeItem("otpresendtime"))
          .finally(() => {
            setActiveStep(2);
            setProcessing(false);
            handleHeapAndProgressBar(usertype, json);
            setSubmitting(false);
          });
      } else {
        let payload: any = { ...json };
        delete payload.is_sole_proprietorship;
        dispatch(
          setSignUpData({
            ...signUpData,
            ...payload,

            usertype: "individual",
          })
        );
        saveRegistrationProgress({
          ...signUpData,
          ...payload,
          usertype: "individual",
          step: 1,
        })
          .then(() => window.localStorage.removeItem("otpresendtime"))
          .finally(() => {
            setProcessing(false);
            setActiveStep(2);
            handleHeapAndProgressBar(usertype, json);
            setSubmitting(false);
          });
      }
    } catch (error: any) {
      setSubmitting(false);
      Sentry.captureException(error);
      setProcessing(false);
    }
  };
  return (
    <div className="flex justify-center pb-5 my-5">
      <div className="p-8 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-lg shadow-lg">
        <h2 className="mb-6 font-bold text-center text-1xl">
          {t("enterPerson")}
        </h2>
        <Formik
          validateOnMount
          validateOnChange
          enableReinitialize
          key="personalinfo"
          initialValues={{
            lastname:
              user?.last_name ||
              signUpData?.last_name ||
              user?.progression?.last_name ||
              "",
            firstname:
              user?.first_name ||
              signUpData?.first_name ||
              user?.progression?.first_name ||
              "",
            phone_number:
              signUpData?.phone_number ||
              user?.phone_number ||
              user?.progression?.phone_number ||
              "",
            country_code:
              signUpData?.country_code ||
              (user?.country_code && "+" + user?.country_code) ||
              user?.progression?.country_code ||
              "",
            address_information:
              signUpData?.address_line ||
              user?.address_line ||
              user?.progression?.address_line ||
              "",
            city:
              signUpData?.city || user?.city || user?.progression?.city || "",
            post_code: signUpData?.post_code || "",
            isPostCodeRequired: "false",
            country: "",
            countryName:
              signUpData?.countryName || user?.progression?.countryName || "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
            isValid,
            setFieldValue,
            setValues,
            handleSubmit,
            setSubmitting,
          }) => (
            <Form className="individual-registration">
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="lastname"
                handleChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("LastName")}
                value={values.lastname}
                label={t("LastName")}
                error={isSubmitting ? "" : t(`${errors.lastname}`)}
                touched={touched.lastname}
              />
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="firstname"
                handleChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("FirstName")}
                value={values.firstname}
                label={t("FirstName")}
                error={isSubmitting ? "" : t(`${errors.firstname}`)}
                touched={touched.firstname}
              />

              <div className="mt-6 mb-6">
                <small>
                  {t("is_number")}{" "}
                  <b>
                    {country}
                    {phone}
                  </b>
                </small>
                <br />
                <CountryPicker
                  selected_mobile_code={values?.country_code}
                  setCountry={(text: any) => {
                    setCountry(text.mobileCode);
                    setCountryName(text.name);
                    setValues({
                      ...values,
                      country_code: text.mobileCode,
                      country: text.code && text.code.toLowerCase(),
                      countryName: text.name,
                      isPostCodeRequired: (
                        text.requires_post_code == 1
                      ).toString(),
                    });
                  }}
                  cash_Out_Method_Name={""}
                  country={values?.countryName}
                  onChange={(text: string) => {
                    setPhone(text);
                    handleChange("phone_number")(text);
                  }}
                  value={values?.phone_number}
                  withPhoneInput={true}
                  country_selected={true}
                  setSelectedCountry={(e: any) => setSelectedCountryReset(e)}
                />
                {errors.phone_number && (
                  <FormErrorText
                    errorMessage={isSubmitting ? "" : errors.phone_number}
                  />
                )}
                {errors.country_code && (
                  <FormErrorText
                    errorMessage={isSubmitting ? "" : errors.country_code}
                  />
                )}
              </div>

              {uservalue == "individual" && (
                <GoogleAutoCompleteField
                  value={values.address_information}
                  country={values.country}
                  className="mb-3 customInputStyle w-full"
                  label={t("AddressInformation")}
                  placeholder={t("AddressInformation")}
                  getPostalCode={(postalCode: any) => {
                    setFieldValue("post_code", postalCode || "");
                  }}
                  getLocality={(locality: any) => {
                    setFieldValue("city", locality || "");
                  }}
                  onChange={(value: any) => {
                    setFieldValue(
                      "address_information",
                      value?.formatted_address || value
                    );
                  }}
                />
              )}

              {/* country city selection */}
              {uservalue == "individual" && (
                <>
                  <InputField
                    type="text"
                    extraClasses="mb-6 customInputStyle"
                    name="post_code"
                    disabled={
                      isEmpty(values?.country_code) ||
                      isEmpty(values?.phone_number) ||
                      errors.country_code !== undefined ||
                      errors.phone_number !== undefined
                    }
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("post_code_placeholder")}
                    value={values.post_code}
                    label={t("post_code_placeholder")}
                    error={isSubmitting ? "" : t(`${errors.post_code}`)}
                    touched={touched.post_code}
                  />
                  <div className="mb-3"></div>
                  <div className="flex flex-col flex-1">
                    <InputField
                      extraClasses="mb-3 customInputStyle"
                      name="city"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("City")}
                      value={values.city}
                      label={t("City")}
                      error={isSubmitting ? "" : t(`${errors.city}`)}
                      touched={touched.city}
                    />
                  </div>
                </>
              )}

              <div>
                {errorsState.length > 0 && (
                  <div className="mb-4">
                    <Alert severity="error">
                      {errorsState.map((errorText: string, index: number) => (
                        <p>
                          <small key={index}>
                            {t(buildString(errorText?.toLowerCase()) || "")}
                          </small>
                        </p>
                      ))}
                    </Alert>
                  </div>
                )}
              </div>

              <div className="flex mt-4 justify-between gap-3">
                <button
                  className="flex-1 rounded-lg btn text-white capitalize md:text-md max-sm:!text-sm bg-[#666]"
                  onClick={handleBack}
                >
                  {t("back")}
                </button>

                <button
                  className="flex-1 rounded-lg btn text-white capitalize md:text-md max-sm:!text-sm bg-[#666]"
                  onClick={() => setisOtpModalOpen(true)}
                  type="button"
                  disabled={processing || !isValid || isSubmitting}
                >
                  {t("next")}
                </button>
                <OtpModal
                  showCustomDescription
                  customDescription={t("otp_verification_description")}
                  showChannel
                  setSubmitting={setSubmitting}
                  hasResend={isOtpSent ? true : false}
                  loading={processing || isSubmitting}
                  formhandleChange={handleChange}
                  open={isOtpModalOpen}
                  onSubmit={() => {
                    if (isOtpSent) {
                      validateOTP(handleSubmit, values, setSubmitting);
                    } else {
                      sendOTP(values, false);
                    }
                  }}
                  onResend={() => {
                    sendOTP(values, true);
                  }}
                  onClose={() => {
                    setisOtpModalOpen(false);
                    setisOtpSent(false);
                    setotp("");
                  }}
                  t={t}
                  otp={otp}
                  setotp={setotp}
                  submitText={isOtpSent ? "submit" : "send_otp"}
                  showOtpField={isOtpSent}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalInfoScreen;
