import { Form, Formik } from "formik";
import {
  addUser,
  changePassword,
  get_phone_otp,
} from "../../store/features/Auth/Auth";
import InputField from "../forms/InputField";
import CountryPicker from "../CountryPicker";
import { extractErrorMessage } from "../../helpers";
import PasswordField from "../forms/PasswordField";
import { currentBalance } from "../../store/features/Transfer/Transfer";
import { editUser, updateBalance } from "../../store/features/Auth/AuthSlice";
import * as Sentry from "@sentry/browser";

const UserInfo = (props: any) => {
  const {
    t,
    user,
    toast,
    yup,
    setCountry,
    setSelectedCountry,
    selectedCountry,
    setNewPhone,
    setSelectedCountryReset,
    setDphKey,
    country,
    new_phone,
    dphkey,
    setOptModalOpen,
    dispatch,
    setOtpCode,
  } = props;

  const requestOtp = () => {
    try {
      const data = {
        country_code: parseInt(country),
        phone_number: new_phone,
        dp_hfkey: dphkey,
      };
      get_phone_otp(data)
        .then(() => {
          setOptModalOpen(true);
          setOtpCode && setOtpCode("");
        })
        .catch((error: any) => {
          const err = extractErrorMessage(error).join(", ");
          toast.error(err);

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const fetchUser = () => {
    try {
      currentBalance()
        .then((response: any) => {
          dispatch(updateBalance(response.client.euro_balance));
          dispatch(editUser(response));
        })
        .catch((error: any) => {
          toast.warning(t(error.data.message));
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="row p-2 pt-4 mb-12 overflow-y-scroll">
      <div className=" col-md-6">
        <p className="font-bold text-gray-600">
          <small>{t("personal_information")}</small>
        </p>
        <small>{t("personal_information_text")}</small>
        <div className="p-4 mt-2 bg-white rounded-md shadow-md ">
          <Formik
            key="form"
            initialValues={{
              firstname: user?.first_name,
              lastname: user?.last_name,
            }}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              const json = {
                first_name: data.firstname,
                last_name: data.lastname,
                country_code: user?.country_code,
                phone_number: user?.phone_number,
              };
              addUser(json, "")
                .then((result: any) => {
                  setSubmitting(false);
                  toast.success(t("Successfully updated"));
                  fetchUser();
                })
                .catch((error: any) => {
                  setSubmitting(false);
                  // toast.success(t(error.message));
                  toast.warning(t(error.data.message));
                  Sentry.captureException(error);
                });
            }}
            validationSchema={yup.object().shape({
              lastname: yup.string().required("LNR"),
              firstname: yup.string().required("FNR"),
            })}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form>
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="firstname"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("FirstName")}
                  value={values.firstname}
                  label={t("FirstName")}
                  error={t(`${errors.firstname}`)}
                  touched={touched.firstname}
                  disabled={user?.is_active || user?.is_verified ? true : false}
                />
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="lastname"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("LastName")}
                  label={t("LastName")}
                  value={values.lastname}
                  error={t(`${errors.lastname}`)}
                  touched={touched.lastname}
                  disabled={user?.is_active || user?.is_verified ? true : false}
                />

                <div className="flex flex-row items-center justify-between mt-4">
                  {!isSubmitting ? (
                    <button
                      type="submit"
                      className={`btn rounded-lg btn-block text-white text-[12.6px] leading-6 font-bold`}
                      disabled={
                        user?.is_active || user?.is_verified ? true : false
                      }
                      style={{
                        backgroundColor: "rgb(3, 115, 117)",
                      }}
                    >
                      {t("update")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className={`btn rounded-lg btn-block bg-gray-700`}
                      disabled={true}
                    >
                      <small className="text-white">
                        <b>Please wait ...</b>
                      </small>
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="mt-4 col-md-6">
        <p className="font-bold text-gray-600">
          <small>{t("Contacts")}</small>
        </p>
        <small>{t("update_phone")}</small>
        <div className="p-4 mt-2 bg-white rounded-md shadow-md ">
          <CountryPicker
            setCountry={(text: any) => {
              setSelectedCountry(text);
              setCountry(text.mobileCode);
            }}
            cash_Out_Method_Name={""}
            country={selectedCountry}
            onChange={(text: string) => {
              setNewPhone(text);
            }}
            value={new_phone}
            withPhoneInput={true}
            country_selected={true}
            setSelectedCountry={(e: any) => setSelectedCountryReset(e)}
          />

          <div>
            <input
              type="text"
              name="dp_hkey"
              onChange={(e: any) => setDphKey(e.target.value)}
            ></input>
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <button
              onClick={() => requestOtp()}
              className={`btn rounded-lg btn-block text-white text-[12.6px] leading-6 font-bold`}
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
            >
              {t("update")}
            </button>
          </div>
        </div>
      </div>

      <div className="mt-4 col-md-6">
        <p className="font-bold text-gray-600">
          <small>{t("security")}</small>
        </p>
        <small>{t("update_password")}</small>
        <div className="p-4 mt-2 bg-white rounded-md shadow-md ">
          <Formik
            initialValues={{
              newPassword_confirmation: "",
              oldPassword: "",
              newPassword: "",
            }}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              changePassword(data)
                .then((res: any) => {
                  setSubmitting(false);
                  toast.success(t("your_password_has_been_saved"));
                  resetForm();
                  setSubmitting(false);
                })
                .catch((error: any) => {
                  const err = extractErrorMessage(error).join(", ");
                  toast.error(err);
                  setSubmitting(false);

                  Sentry.captureException(error);
                  resetForm();
                });
            }}
            validationSchema={yup.object().shape({
              oldPassword: yup.string().required("password_required"),
              newPassword: yup
                .string()
                .required("password_required")
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?\-_])[A-Za-z\d#$@!%&*?\-_]{8,30}$/g,
                  "P_REG"
                ),
              newPassword_confirmation: yup
                .string()
                .required("password_required")
                .oneOf([yup.ref("newPassword"), null], "PSM"),
            })}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form>
                <PasswordField
                  name="oldPassword"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("oldPass")}
                  value={values.oldPassword}
                  label={t("oldPass")}
                  error={t(`${errors.oldPassword}`)}
                  type="password"
                  touched={touched.oldPassword}
                />
                <PasswordField
                  name="newPassword"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("newPass")}
                  value={values.newPassword}
                  label={t("newPass")}
                  error={t(`${errors.newPassword}`)}
                  type="password"
                  touched={touched.newPassword}
                />
                <PasswordField
                  name="newPassword_confirmation"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("confirmPass")}
                  value={values.newPassword_confirmation}
                  label={t("confirmPass")}
                  error={t(`${errors.newPassword_confirmation}`)}
                  type="password"
                  touched={touched.newPassword_confirmation}
                />

                <div className="flex flex-row items-center justify-between mt-4">
                  <button
                    className={`btn rounded-lg btn-block text-white text-[12.6px] leading-6 font-bold`}
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t("update")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
