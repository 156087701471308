import { Button } from "@material-ui/core";
import Contact from "../Contact";
import Contacts from "../Contacts";
import CountryPicker from "../CountryPicker";
import { useMemo } from "react";
import { toast } from "material-react-toastify";
import { checkIfUserExists } from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";

import {
  buildString,
  getCountryByCode,
  getPreferredComChannel,
} from "../../utilities/help";
import {
  addBeneficiary,
  addTransferCountry,
  setBeneFound,
  setPreferredComChannel,
} from "../../store/features/Transfer/TransferSlice";
import { useDispatch } from "react-redux";

const BeneficiarySelector = (props: any) => {
  const {
    t,
    setCountry,
    phone,
    countryPickerOnChange,
    country,
    favorites,
    userCheck,
    setSelectedCountry,
    isLast,
    setBeneficiary,
    user,
    phoneValue,
    setUserCheck,
    transfer,
  } = props;

  const dispatch = useDispatch();

  const fetchBene = () => {
    try {
      if (phone === "") return;
      const selectedPhone = `+ ${phoneValue}`;
      if (selectedPhone === user?.full_phone_number) {
        toast.error(t("cantSendToSelf"));
        return;
      }
      dispatch(addBeneficiary(null));
      setUserCheck(true);
      checkIfUserExists({
        country_code: country["dialCode"],
        phone_number: buildString(phone),
      })
        .then((result: any) => {
          setUserCheck(false);
          dispatch(
            setPreferredComChannel(
              getPreferredComChannel(
                transfer.danaPayCountries,
                country["dialCode"]
              )
            )
          );
          const beneficiary_country = getCountryByCode(
            transfer.danaPayCountries,
            country["dialCode"]
          );
          if (result.exists) {
            dispatch(setBeneFound(true));
            dispatch(addBeneficiary(result.customer));
            setBeneficiary(result.customer);
          } else {
            dispatch(setBeneFound(false));
            setBeneficiary({
              country_code: country["dialCode"],
              phone_number: buildString(phone),
            });

            dispatch(
              addBeneficiary({
                country_code: country["dialCode"],
                phone_number: buildString(phone),
              })
            );
          }
          dispatch(addTransferCountry(beneficiary_country));
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          setUserCheck(false);
          if (error.status === 422 || error.status === 500) {
            const err: any = Object.values(error.data.errors)[0];
            toast.error(err[0]);
          }
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const favoriteList = useMemo(() => {
    if (favorites !== undefined) {
      return favorites
        .filter(
          (usr: any) =>
            country.dialCode !== "" &&
            parseInt(usr?.favorite?.country_code) === parseInt(country.dialCode)
        )
        .filter((usr: any) => usr.favorite.first_name !== null);
    } else {
      return [];
    }
  }, [favorites, country]);

  return (
    <div className="row flex mx-auto justify-center">
      <div className="p-4 max-sm:!px-3 col-lg-6 col-md-8 col-12 bg-white rounded-lg shadow-lg lg:p-8 md:p-3 sm:p-3">
        <h3 className="my-3 text-2xl font-bold text-center">
          {t("beneficiary")}
        </h3>
        <p className="text-center">{t("enterPhoneNumber")}</p>
        <div className="m-auto mt-3 mb-2 w-200">
          <CountryPicker
            setCountry={setCountry}
            cash_Out_Method_Name={""}
            country={null}
            onChange={countryPickerOnChange}
            value={phone}
            withPhoneInput={true}
            withChildren={true}
            textChange={false}
            setSelectedCountry={setSelectedCountry}
          >
            <div className="p-[10px] mb-[10px]">
              <div className="flex items-center justify-around p-2">
                <small className="text-center">{t("select_user_text")}</small>
              </div>

              {country.dialCode !== "" && (
                <div className="flex flex-row items-center overflow-x-scroll">
                  <a>
                    <Contacts buttonOnly={true} />
                  </a>
                  {favoriteList.map((contact: any, index: number) => (
                    <Contact
                      keyprop={index}
                      contact={contact}
                      moveToTransfer={() => setBeneficiary(contact.favorite)}
                    />
                  ))}
                </div>
              )}
            </div>
          </CountryPicker>

          {userCheck ? (
            <Button disabled>{t("processing")}</Button>
          ) : (
            <button
              onClick={fetchBene}
              className="w-full px-10 my-6 rounded-lg btn"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              data-transfer-receipent-details="receipentdetails"
              disabled={!phone}
            >
              <span
                className="text-white capitalize"
                data-transfer-receipent-details="receipentdetails"
              >
                {isLast ? t("finish") : t("next")}
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeneficiarySelector;
