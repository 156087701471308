import React, { useState, useEffect, useRef } from "react";
import {
  downloadCSV,
  fetch_receipt,
  getTransferDetails,
} from "../../store/features/Transfer/Transfer";
import * as Sentry from "@sentry/browser";
import "./style.css";
import { useAppSelector } from "../../store/hooks";
import StatusComp from "../StatusComp";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import CashLayout from "../CashLayout";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";

import {
  TableCell,
  TableBody,
  TableRow,
  Table,
  Paper,
  TableContainer,
} from "@material-ui/core";
import StatusButtons from "../StatusButtons";
import useResize from "../../hooks/useResize";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { performPDFDownload } from "../../utilities/help";
import { toast } from "material-react-toastify";
import { upload_transaction_docs } from "../../store/features/Auth/Auth";
import InputField from "../forms/InputField";
import DocumentItem from "./DocumentItem";
import { useErrorHandler } from "react-error-boundary";
import FileUpload from "../FileUpload";

const no_ben_card_type = ["deposit", "pay_out", "withdraw"]; // for these operation types,  dont show bene card in the design

const TransactionDetails: React.FC<any> = ({ selectedTransfer, close }) => {
  const [fetchData, setFetchedData] = useState<any>(null);
  const [fetching, setFetching] = useState<any>(true);
  const {
    transfer,
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);
  const { t } = useTranslation();
  const [w, h] = useResize();
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isSender = user?.id === selectedTransfer?.source_user?.id;
  const [title, setTitle] = useState("");

  const directFeeSObject = selectedTransfer?.transfer?.data
    ? JSON.parse(selectedTransfer.transfer.data)
    : null;

  const getOperationMetaData = () => {
    if (selectedTransfer) {
      getTransferDetails(selectedTransfer?.payment_id)
        .then((response: any) => {
          setFetching(false);
          setFetchedData(response);
        })
        .catch((error: any) => {
          setFetching(false);
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
        });
    } else {
    }
  };

  const download_receipt = async () => {
    try {
      setDownloading(true);
      try {
        const result = await fetch_receipt(selectedTransfer.payment_id);
        performPDFDownload(result, selectedTransfer.payment_id);
        setDownloading(false);
        toast.success(t("download_done"));
      } catch (error: any) {
        toast.error(t("error_downloading"));
        setDownloading(false);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const upload_documents = () => {
    setIsOpen(true);
  };

  const upload = (event: any) => {
    try {
      // const files: any = Object.values(event.target.files);
      const files: any = event;
      if (files.length === 0) {
        toast.error(t("Please select a file"));
        return;
      }
      if (title === "") {
        toast.error(t("Please enter the document title/name"));
        return;
      }

      const { name, type, size } = files[0];

      if (size < 5000000) {
        const formData = new FormData();
        const ext = name.split(".");
        formData.append("file", files[0]);
        formData.append("name", title);
        setUploading(true);

        upload_transaction_docs(formData, fetchData.payment_id)
          .then((res) => {
            setUploading(false);
            setIsOpen(false);
            getOperationMetaData();
            setTitle("");
          })
          .catch((error: any) => {
            toast.error(error?.data?.message);
            setUploading(false);
            setIsOpen(false);
            toast.warning(t(error?.data?.message));
            Sentry.captureException(error);
          });
      } else {
        toast.error(t("file_to_large"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    getOperationMetaData();
  }, []);

  return (
    <>
      {fetching ? (
        <div className="w-full p-4 fh-full" style={{ height: 600 }}>
          <b className="text-gray-400">
            <small>{t("status")}</small>
          </b>
          <Skeleton height={80} />
          <br />
          <b className="text-gray-400">
            <small>{t("Details")}</small>
          </b>
          <Skeleton height={150} />
          <br />
          <b className="text-gray-400">
            <small>{t("Details")}</small>
          </b>
          <Skeleton height={360} />
        </div>
      ) : (
        <div className="h-full overflow-y-scroll">
          <div className="flex flex-row flex-wrap gap-2 items-center justify-end px-4 pt-2 mt-2 mb-2 ">
            {["instant_transfer", "bulk_transfer"].includes(
              fetchData?.operation_type
            ) &&
              !fetchData?.aborted && (
                <>
                  {isSender && (
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-block btn-sm"
                        onClick={() => upload_documents()}
                        style={{
                          backgroundColor: "#037375",
                        }}
                      >
                        {uploading ? (
                          <>
                            <small
                              className="ml-2 font-bold text-white"
                              style={{ fontSize: 11 }}
                            >
                              {t("Please_Wait")}
                            </small>
                          </>
                        ) : (
                          <>
                            <i
                              className="m-0 text-white fa fa-upload"
                              style={{ fontSize: 10 }}
                            />
                            <small
                              className="ml-2 font-bold text-white"
                              style={{ fontSize: 11 }}
                            >
                              {t("add_documents")}
                            </small>
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </>
              )}

            {[
              "deposit",
              "pay_out",
              "instant_transfer",
              "withdraw",
              "bulk_transfer",
            ].includes(fetchData?.operation_type) && (
              <>
                {isSender && (
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-block btn-sm"
                      onClick={() => download_receipt()}
                      style={{
                        backgroundColor: "#037375",
                      }}
                    >
                      {downloading ? (
                        <>
                          <small
                            className="ml-2 font-bold text-white"
                            style={{ fontSize: 11 }}
                          >
                            {t("Please_Wait")}
                          </small>
                        </>
                      ) : (
                        <>
                          <i
                            className="m-0 text-white fa fa-download"
                            style={{ fontSize: 10 }}
                          />
                          <small
                            className="ml-2 font-bold text-white"
                            style={{ fontSize: 11 }}
                          >
                            {t("download")}
                          </small>
                        </>
                      )}
                    </button>
                  </div>
                )}
              </>
            )}
            <StatusButtons transfer={fetchData} transcationsObject={transfer} />
          </div>
          <div>
            <div className="px-4 status ">
              <div className="mb-3">
                <small className="font-bold">{t("status")}</small>
              </div>
              <div className="p-2 shadow-md">
                {fetchData?.history?.payment && (
                  <StatusComp
                    body={fetchData?.history?.payment}
                    t={t}
                    transaction={fetchData}
                    user={user}
                  />
                )}
                {fetchData?.history?.transfer && (
                  <StatusComp
                    body={fetchData?.history?.transfer}
                    t={t}
                    transaction={fetchData}
                    user={user}
                  />
                )}
              </div>
            </div>

            {!no_ben_card_type.includes(fetchData?.operation_type) && (
              <>
                {!isSender ? (
                  <div className="px-4 head">
                    <div className="mb-3">
                      <small className="font-bold">{t("Sender")}</small>
                    </div>
                    <div className="p-4 bg-white shadow-md">
                      {fetchData?.source_user?.is_individual ? (
                        <div>
                          <small className="flex justify-between mb-1">
                            <span>{t("Name")}</span>
                            <b>{fetchData?.source_user?.full_name}000</b>
                          </small>

                          <small className="flex justify-between mb-1">
                            <span>{t("Email")}</span>
                            <b>{fetchData?.source_user?.email}</b>
                          </small>

                          <small className="flex justify-between mb-1">
                            <span>{t("CompanyRegistrationNo")}</span>
                            <b>
                              {fetchData?.source_user?.company?.registered_id}
                            </b>
                          </small>
                        </div>
                      ) : (
                        <div>
                          <small className="flex justify-between mb-1">
                            <span>{t("Name")}</span>
                            <b>{fetchData?.source_user?.full_name}</b>
                          </small>

                          <small className="flex justify-between mb-1">
                            <span>{t("Email")}</span>
                            <b>{fetchData?.source_user?.email}</b>
                          </small>

                          <small className="flex justify-between mb-1">
                            <span>{t("phone")}</span>
                            <b>{fetchData?.source_user?.full_phone_number}</b>
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="px-4 head">
                    <div className="mb-3">
                      <small className="font-bold">
                        {fetchData?.operation_type === "bulk_transfer" ||
                        fetchData?.transfer?.bulk_transfer_recipient_details
                          ?.length > 0
                          ? t("recipients")
                          : t("Receiver")}
                      </small>
                    </div>
                    {fetchData?.operation_type === "bulk_transfer" ||
                    fetchData?.transfer?.bulk_transfer_recipient_details
                      ?.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {fetchData?.transfer?.destination_users?.map(
                              (row: any, index: number) => (
                                <TableRow
                                  className="cursor-pointer hover:bg-gray-100"
                                  key={index}
                                >
                                  <TableCell>
                                    <small>
                                      {row.is_individual
                                        ? t("Individual")
                                        : t("company")}
                                    </small>
                                  </TableCell>
                                  <TableCell className="text-xs capitalize">
                                    <small>{`${row.last_name} ${row.first_name}`}</small>
                                  </TableCell>
                                  <TableCell>
                                    <small>{row.full_phone_number}</small>
                                  </TableCell>
                                  <TableCell>
                                    <small>{row.email}</small>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="p-4 bg-white shadow-md">
                        {fetchData?.destination_user?.is_individual ? (
                          <div>
                            <small className="flex justify-between mb-1">
                              <span>{t("Name")}</span>
                              <b>{fetchData?.destination_user?.full_name}</b>
                            </small>

                            <small className="flex justify-between mb-1">
                              <span>{t("Email")}</span>
                              <b>{fetchData?.destination_user?.email}</b>
                            </small>
                          </div>
                        ) : (
                          <div>
                            <small className="flex justify-between mb-1">
                              <span>{t("Name")}</span>
                              <b>{fetchData?.destination_user?.full_name}</b>
                            </small>

                            <small className="flex justify-between mb-1">
                              <span>{t("Email")}</span>
                              <b>{fetchData?.destination_user?.email}</b>
                            </small>

                            <small className="flex justify-between mb-1">
                              <span>{t("company")}</span>
                              <b>
                                {fetchData?.destination_user?.company?.name}
                              </b>
                            </small>

                            <small className="flex justify-between mb-1">
                              <span>{t("CompanyRegistrationNo")}</span>
                              <b>
                                {
                                  fetchData?.destination_user?.company
                                    ?.registered_id
                                }
                              </b>
                            </small>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            <div className="px-4 details">
              <div className="mb-3">
                <small className="font-bold">{t("Details")}</small>
              </div>
              <div className="p-4 bg-white shadow-md">
                {fetchData?.type === "bank_transfer" && isSender && (
                  <small className="flex justify-between mb-3">
                    <span>{t("payment_reference_number")}</span>
                    <b>{fetchData?.source_user?.payment_reference_number}</b>
                  </small>
                )}

                <small className="flex justify-between mb-3">
                  <span>{t("Payment_method")}</span>
                  <b className="capitalize">{t(fetchData?.type)}</b>
                </small>

                {!["deposit", "pay_out", "withdraw"].includes(
                  fetchData?.operation_type
                ) && (
                  <small className="flex justify-between mb-3">
                    <span>{t("reason")}</span>
                    <b>{fetchData?.transfer?.reason}</b>
                  </small>
                )}

                <small className="flex justify-between mb-3">
                  <span>{t("Sent_amount")}</span>
                  <b>
                    <CashLayout
                      cash={
                        // fetchData?.operation_type === "bulk_transfer" || fetchData?.operation_type === "direct_transfer"
                        //   ? fetchData?.transfer?.bulk_transfer_recipient_details?.reduce(
                        //       (sum: number, user: any) =>
                        //         sum + parseFloat(user.amount_in_euros),
                        //       0
                        //     )
                        //     : fetchData?.amount_without_fee
                        fetchData?.operation_type === "bulk_transfer" ||
                        fetchData?.amount_without_fee === null
                          ? fetchData?.transfer?.bulk_transfer_recipient_details?.reduce(
                              (sum: number, user: any) =>
                                sum + parseFloat(user.amount_in_euros),
                              0
                            )
                          : fetchData?.transfer?.amount_without_fee ===
                              undefined ||
                            fetchData?.transfer?.amount_without_fee === null
                          ? fetchData?.amount_without_fee
                          : fetchData?.transfer?.amount_without_fee
                      }
                      exchange_rate={fetchData?.exchange_rate_applied}
                    />
                  </b>
                </small>

                {isSender ? (
                  <>
                    <small className="flex justify-between mb-3">
                      <span>{t("Fees")}</span>
                      <b>
                        <CashLayout
                          cash={
                            fetchData?.applied_fees?.value ||
                            fetchData?.transfer?.applied_fees?.value
                          }
                        />
                      </b>
                    </small>
                    {fetchData?.operation_type === "direct_transfer" &&
                      fetchData?.transfer?.type !== "BulkTransfer" && (
                        <small className="flex justify-between mb-3">
                          <span>{t("withdrawal_Fees")}</span>
                          <b>
                            <CashLayout
                              cash={
                                directFeeSObject?.cashout_fee === undefined ||
                                directFeeSObject?.cashout_fee === null
                                  ? 0
                                  : directFeeSObject?.cashout_fee
                              }
                            />
                          </b>
                        </small>
                      )}
                  </>
                ) : null}

                <small className="flex justify-between mb-3">
                  <span>{t("Exchange_rate")}</span>
                  <b>1EUR = {fetchData?.exchange_rate_applied} CFA</b>
                </small>

                <small
                  className="flex justify-between mb-3"
                  style={{ color: "#037375" }}
                >
                  <span> {t("Received_amount")}</span>
                  <b>
                    <b>
                      {fetchData?.operation_type === "bulk_transfer" ? (
                        <CashLayout
                          cash={
                            fetchData?.transfer?.bulk_transfer_recipient_details.reduce(
                              (sum: number, user: any) =>
                                sum + parseFloat(user.amount_in_euros),
                              0
                            ) * fetchData.exchange_rate_applied
                          }
                          isCfa={true}
                          exchange_rate={1}
                        />
                      ) : (
                        <b>{fetchData?.amount_in_receiver_currency}</b>
                      )}
                    </b>
                  </b>
                </small>
              </div>
            </div>

            {/* {(["instant_transfer"].includes(fetchData?.operation_type) || ["bulk_transfer"].includes(fetchData?.operation_type)) && */}
            {fetchData?.transfer?.documents && isSender && (
              <div className="px-4 details">
                <div className="mb-3">
                  <small className="font-bold">{t("Documents")}</small>
                </div>
                <div className="bg-white shadow-md">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {fetchData?.transfer?.documents?.map(
                          (row: any, index: number) => (
                            <DocumentItem
                              index={index}
                              key={index}
                              row={row}
                              isDisabled={["bulk_transfer"].includes(
                                fetchData?.operation_type
                              )}
                              transfer_id={fetchData.transfer.id}
                              getOperationMetaData={getOperationMetaData}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
          </div>

          <div style={{ height: 100 }} />
        </div>
      )}

      {/* add documents modal */}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="p-10 col-md-12">
            {uploading ? (
              <div className="flex flex-col items-center justify-center p-20">
                <p className="text-2xl">
                  <b>{t("Uploading")}</b>
                </p>
                <p>
                  <small>{t("Please_wait")}</small>
                </p>
              </div>
            ) : (
              <>
                <div>
                  <div className="flex justify-between w-full items-center">
                    <p className="font-bold">{t("drop_file_header")}</p>
                    <button
                      onClick={() => {
                        setIsOpen(false);
                        setTitle("");
                      }}
                      style={{ height: 40, width: 40, borderRadius: 20 }}
                      className="flex items-center justify-center shadow-lg"
                    >
                      <i className="m-0 fa fa-close"></i>
                    </button>
                  </div>
                  <div className="my-2">
                    <InputField
                      name="title"
                      handleChange={(text: any) => setTitle(text.target.value)}
                      value={title}
                      label={t("file_title")}
                    />
                  </div>
                  <small></small>

                  <FileUpload
                    onDrop={(acceptedFiles: any) => {
                      upload(acceptedFiles);
                    }}
                    // accept=".csv"
                    multiple={false}
                  >
                    <label className="flex justify-center p-10 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                      <span className="flex items-center space-x-2">
                        <span className="font-medium text-gray-600">
                          {t("drop_file")}
                          <span className="mx-2 text-blue-600 underline">
                            {t("browse_file")}
                          </span>
                        </span>
                      </span>
                      <small>{t("")}</small>
                    </label>
                  </FileUpload>

                  {/* <label className="flex justify-center p-10 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                    <span className="flex items-center space-x-2">
                      <span className="font-medium text-gray-600">
                        {t("drop_file")}
                        <span className="mx-2 text-blue-600 underline">
                          {t("browse_file")}
                        </span>
                      </span>
                    </span>
                    <small>{t("")}</small>
                    <input
                      type="file"
                      name="file_upload"
                      className="hidden"
                      onChange={upload}
                    />
                  </label> */}
                </div>
              </>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
      </Dialog>
    </>
  );
};

export default TransactionDetails;
