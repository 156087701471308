import { CircularProgress } from "@material-ui/core";
import { toast } from "material-react-toastify";

const PaymentMethods = ({ props, previous }: any) => {
  const {
    transfer,
    t,
    paymentMethod,
    errorGot,
    checkingForRestriction,
    isUserRestricted,
    handleCashMethod,
    fetchingFees,
    hideWithdrawModal,
    processingPayment,
    submitTransaction,
    feesCalculated,
    user,
    checkingForWithdrawProviders,
    googleSheetsLoading,
    reason,
    setpaymentActiveStep,
  } = props;

  // handle form to withdrawal page
  const proceedToWithdrawalMode = () => {
    if (reason !== "" && paymentMethod !== "") {
      setpaymentActiveStep(1);
    } else {
      if (reason == "") {
        toast.error(t("reasonError"));
      }
      if (paymentMethod == "") {
        toast.error(t("selectCashIn"));
      }
    }
  };

  const disableDirectPayment: any = () => {
    // true for active verified beneficiary company and individual user
    if (
      (user?.is_individual == true || user?.is_individual == 1) &&
      (transfer?.beneficiary?.is_active == true ||
        transfer?.beneficiary?.is_active == 1) &&
      (transfer?.beneficiary?.is_verified == true ||
        transfer?.beneficiary?.is_verified == 1) &&
      (transfer?.beneficiary?.is_individual == false ||
        transfer?.beneficiary?.is_individual == 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="scrollDiv col-md-6 m-0 p-0">
      {/* cash in methods */}
      <div className="max-sm:!px-3 outerfieldBox p-4">
        <div className="flex flex-row customeDesignwithdraw customeCacheDesign">
          <label className="withdrawTitle px-3">
            {t("selectCashinMethod")}
          </label>
          <div
            className={`${
              checkingForRestriction ||
              checkingForWithdrawProviders ||
              feesCalculated ||
              fetchingFees ||
              googleSheetsLoading
                ? "pointer-events-none "
                : "pointer-events-auto "
            }bacnkCheckBoxWrapper p-2 my-3 flex flex-col gap-2 relative`}
          >
            {checkingForRestriction && (
              <div className="flex justify-center items-center z-50 w-full h-48">
                <CircularProgress />
              </div>
            )}
            {!checkingForRestriction &&
              transfer.loggedInUserCountry?.cash_in_methods &&
              transfer.loggedInUserCountry?.cash_in_methods
                .filter((itm: any) => {
                  if (isUserRestricted) {
                    return (
                      itm?.cash_in_method?.payment_type?.name == "bank_transfer"
                    );
                  } else {
                    return true;
                  }
                })
                ?.map((value: any, index: any) => {
                  return (
                    <>
                      <div
                        className="wrapperBankCheckBox m-0 innerBankBox"
                        key={"transferPaymentCheckbox" + index}
                      >
                        <div className="wrapperBankCheckBoxwrapper">
                          <input
                            type="checkbox"
                            className="default:ring-2"
                            onChange={(e: any) => {
                              handleCashMethod(
                                e.target.checked,
                                value.cash_in_method?.name.toLowerCase()
                              );
                            }}
                            name={value?.cash_in_method?.name}
                            checked={
                              value.cash_in_method?.name.toLowerCase() ===
                              paymentMethod
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                        <label>
                          {t(
                            value?.cash_in_method?.name
                              ?.toLowerCase()
                              .split(" ")
                              .join("_")
                          )}
                        </label>
                      </div>

                      {/* max limit error message */}
                      {errorGot &&
                        !fetchingFees &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="mb-2 innerBankBox text-red-500">
                            {t("max_amount_exceeded_cashin_message")}
                          </small>
                        )}

                      {value.cash_in_method?.name.toLowerCase() ===
                        paymentMethod && (
                        <small className="mb-2 innerBankBox">
                          {t("max_limit")}
                          {value?.cash_in_method?.max_amount} EUR
                        </small>
                      )}

                      {value?.cash_in_method?.name?.toLowerCase() ===
                        "bank transfer" &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="innerBoxDesc mb-2 innerBankBox">
                            <b>{t("bank_transfer_instructions_title")}</b>
                            {t("bank_transfer_instructions")}
                          </small>
                        )}
                      {value?.cash_in_method?.name?.toLowerCase() ===
                        "balance" &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="innerBoxDesc mb-2 innerBankBox">
                            <b>{t("balance_instructions_title")}</b>
                            {t("balance_instructions")}
                          </small>
                        )}
                      {value?.cash_in_method?.name?.toLowerCase() ===
                        "bank card" &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="innerBoxDesc mb-2 innerBankBox">
                            <b>{t("bank_card_instructions_title")}</b>{" "}
                            {t("bank_card_instructions")}
                          </small>
                        )}
                    </>
                  );
                })}
          </div>
        </div>

        <div className="max-sm:!px-3 flex gap-3 max-sm:flex-col">
          <button
            onClick={previous}
            className="w-full rounded-lg btn btn-dark btn-block"
          >
            {t("back")}
          </button>
          <button
            className="w-full rounded-lg btn btn-block text-white capitalize"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            onClick={() => {
              hideWithdrawModal || disableDirectPayment()
                ? submitTransaction()
                : proceedToWithdrawalMode();
            }}
            disabled={
              feesCalculated ||
              errorGot !== "" ||
              processingPayment ||
              checkingForWithdrawProviders ||
              googleSheetsLoading
            }
          >
            {processingPayment ? t("processing") : t("continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
