import { useEffect, useMemo, useState } from "react";
import {
  discovery,
  saveRegistrationProgress,
  signUp,
} from "../../store/features/Auth/Auth";
import DiscoveryModal from "../DiscoveryModal/DiscoveryModal";
import * as Sentry from "@sentry/browser";
import CustomModal from "../CustomModal";
import { useDispatch } from "react-redux";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import { isEmpty } from "../../utilities/help";

const DiscoveryAndUsertypeModal = (props: any) => {
  const {
    discoverySubStep,
    toast,
    t,
    discoveryMethod,
    setdiscoverySubStep,
    setProcessing,
    typeOfUser,
    activeStep,
    handleNext,
    handleUserType,
    setprogressBarData,
    signUpData,
    processing,
    user,
  } = props;
  const dispatch = useDispatch();

  const [confirmationModalOpen, setconfirmationModalOpen] = useState(false);
  const usertype = useMemo(() => {
    const usertype = typeOfUser.find((itm: any) => itm.active);
    return usertype.value;
  }, [typeOfUser]);

  const Content = () => {
    return (
      <div className="flex flex-col h-48 justify-evenly">
        <div>{t("usertype_info_modal_body_1")}</div>
        <div>
          {t("usertype_info_modal_body_2")}
          <b>{t("usertype_info_modal_body_3")}</b>
          {t("usertype_info_modal_body_4")}
        </div>
        <b>{t("usertype_info_modal_body_5")}</b>
      </div>
    );
  };
  const [isSoleProprietorship, setIsSoleProprietorship] = useState(
    signUpData?.is_sole_proprietorship || false
  );
  // set proprietorship from saved data
  useEffect(() => {
    if (!isEmpty(signUpData?.is_sole_proprietorship)) {
      setIsSoleProprietorship(signUpData?.is_sole_proprietorship);
    } else if (!isEmpty(user?.progression?.is_sole_proprietorship)) {
      setIsSoleProprietorship(user?.progression?.is_sole_proprietorship);
    }
  }, [signUpData, user]);

  const onNext = () => {
    try {
      const usertype: any = typeOfUser.find((itm: any) => itm.active);
      setProcessing(true);
      dispatch(
        setSignUpData({
          ...signUpData,
          ...user?.progression,
          usertype: usertype.value,
          is_sole_proprietorship: isSoleProprietorship,
          step: 0,
        })
      );
      saveRegistrationProgress({
        ...signUpData,
        ...user?.progression,
        usertype: usertype.value,
        is_sole_proprietorship: isSoleProprietorship,
        step: 0,
      }).finally(() => {
        setProcessing(false);
        handleNext();
        setprogressBarData({
          progress: 25,
          title:
            usertype.value === "individual"
              ? "personal_information"
              : "contact_information",
        });
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <CustomModal
        open={confirmationModalOpen}
        containerClasses={
          "w-full h-full flex justify-center text-center items-center"
        }
        modalClasses={"bg-white m-auto p-4 rounded col-md-5 col-lg-4 col-11"}
        titleClasses={"font-bold text-2xl max-sm:text-xl mt-2 mb-4"}
        title={t("usertype_info_modal_title")}
        contentClasses={"mt-4"}
        content={t("usertype_info_modal_body")}
        onBack={() => setconfirmationModalOpen(false)}
        onNext={onNext}
        onBackTitle={"usertype_info_modal_back"}
        onNextTitle={"usertype_info_modal_continue"}
        buttonContainerClasses={"flex gap-3"}
        contentComponent={<Content />}
        backBtnClasses={"bg-white !text-black border"}
        nextBtnClasses={processing && "disabled"}
      />
      {discoverySubStep == 1 && (
        <DiscoveryModal
          extraclass={"py-5 flex justify-center"}
          isDashboardModal="false"
          selectedValue={discoveryMethod}
          onNext={(values: any) => {
            discovery(values)
              .then((res: any) => {
                dispatch(
                  setSignUpData({
                    ...signUpData,
                    meta: values,
                    step: 0,
                  })
                );
                saveRegistrationProgress({
                  ...signUpData,
                  meta: values,
                  step: 0,
                }).finally(() => setdiscoverySubStep(2));
              })
              .catch((error: any) => {
                toast.warning(t(error?.data?.message));
                Sentry.captureException(error);

                setProcessing(false);
                error?.data?.errors?.referral_code?.forEach((element: any) => {
                  toast.warning(t(element));
                });
              });
          }}
        />
      )}
      {discoverySubStep == 2 && (
        <>
          <div className="col-md-4 col-11 mx-auto pb-5 my-5 p-8 max-sm:px-4 bg-white rounded-lg shadow-lg">
            <div className="font-bold text-center text-1xl">
              {t("Account_Type")}
            </div>
            <div className="mb-6 text-sm text-center">
              {t("select_account_type")}
            </div>
            {/* user type selection */}

            <div className="mb-6">
              <div className="flex flex-col gap-2 p-2 bacnkCheckBoxWrapper">
                {typeOfUser.map((usertype: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className="m-0 wrapperBankCheckBox innerBankBox"
                    >
                      <div className="wrapperBankCheckBoxwrapper">
                        <input
                          type="checkbox"
                          className="default:ring-2"
                          name={usertype.value}
                          onChange={(e) => {
                            handleUserType(usertype.title);
                          }}
                          checked={usertype.active}
                        />
                        <span className="checkmark"></span>
                      </div>
                      <label className="md:text-md max-sm:!text-sm">
                        {t(usertype?.title)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            {usertype == "business" && (
              <div className="flex flex-col gap-2 p-2 bg-white bacnkCheckBoxWrapper">
                <div className="m-0 wrapperBankCheckBox innerBankBox">
                  <div className="wrapperBankCheckBoxwrapper min-w-fit">
                    <input
                      type="checkbox"
                      className="default:ring-2"
                      name={t("enterpreneur_label")}
                      onChange={(e) => {
                        setIsSoleProprietorship(e.target.checked);
                      }}
                      checked={isSoleProprietorship}
                    />
                    <span className="checkmark"></span>
                  </div>
                  <label className={`md:text-md max-sm:!text-sm`}>
                    {t("enterpreneur_label")}
                  </label>
                </div>
              </div>
            )}

            {/* info text */}
            <div className="border !border-[#2196F3] p-4 mb-4 rounded">
              <div className="flex items-start">
                {/* <i className="fa fa-info-circle px-2 !text-2xl color-[#0288D1]"></i> */}
                <div className="flex flex-0">
                  <svg
                    width={40}
                    height={40}
                    className="px-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M10.0833 6.41667H11.9167V8.25H10.0833V6.41667ZM10.0833 10.0833H11.9167V15.5833H10.0833V10.0833ZM11 1.83333C5.94001 1.83333 1.83334 5.94 1.83334 11C1.83334 16.06 5.94001 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94 16.06 1.83333 11 1.83333ZM11 18.3333C6.95751 18.3333 3.66668 15.0425 3.66668 11C3.66668 6.9575 6.95751 3.66667 11 3.66667C15.0425 3.66667 18.3333 6.9575 18.3333 11C18.3333 15.0425 15.0425 18.3333 11 18.3333Z"
                      fill="#0288D1"
                    />
                  </svg>
                </div>
                <div className="text-sm">
                  <div className="mb-2">
                    {t("usertype_info_1")}
                    <b>{t("usertype_info_2")}</b>
                    {t("usertype_info_3")}
                  </div>
                  {t("usertype_info_4")}
                  <b>{t("usertype_info_5")}</b>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between gap-3">
              <button
                className="w-full rounded-lg btn btn-block rounded-lg-primary text-white capitalize md:text-md max-sm:!text-sm"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                onClick={() => setdiscoverySubStep(1)}
                disabled={
                  activeStep === 0 ||
                  Object.keys(discoveryMethod).length > 0 ||
                  processing
                }
              >
                {t("back")}
              </button>
              <button
                disabled={processing}
                className="w-full rounded-lg btn btn-block rounded-lg-primary text-white capitalize md:text-md max-sm:!text-sm"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                onClick={() =>
                  usertype == "individual"
                    ? onNext()
                    : setconfirmationModalOpen(true)
                }
              >
                {t("next")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DiscoveryAndUsertypeModal;
