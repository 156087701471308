import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import InputField from "../forms/InputField";
import { applyRate, buildLimitError } from "../../utilities/help";
import { Alert } from "@material-ui/lab";

const AmountAndReason = ({ props, next, cashInMethod }: any) => {
  const {
    toast,
    amountInEuros,
    reason,
    amountInCFA,
    t,
    setReason,
    setisCFAFirst,
    userBalance,
    setLimitError,
    paymentMethod,
    transfer,
    checkCashInLimit,
    setAmountInEuros,
    setAmountInCFA,
    fetchingFees,
    spread_config,
    errorGot,
    notReceivingCountry,
    setErrorGot,
  } = props;

  useEffect(() => {
    try {
      if (transfer?.loggedInUserCountry?.cash_in_methods) {
        let maxAmount: number = 0;
        transfer?.loggedInUserCountry?.cash_in_methods.forEach(
          (method: any) => {
            const limit: number = parseInt(method?.cash_in_method?.max_amount);
            if (limit > maxAmount) {
              maxAmount = limit;
            }
          }
        );

        if (parseInt(amountInEuros) > maxAmount) {
          toast.error(`${t("max_amount_exceeded")}${maxAmount}`);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer.loggedInUserCountry?.cash_in_methods, amountInEuros]);

  const validate = () => {
    if (reason.length === 0) {
      toast.error(t("reasonError"));
      return;
    }
    if (amountInEuros.length === 0) {
      toast.error(t("the_euro_amount_field_is_required"));
      return;
    }
    if (amountInCFA.length === 0) {
      toast.error(t("enterCfa"));
      return;
    }
    next();
  };

  return (
    <div className="scrollDiv flex flex-col col-md-6 p-0 m-0">
      <div className="p-4 max-sm:!px-3">
        <label className="withdrawTitle">{t("enter_reason")}</label>
        <div className="mb-3 ">
          <InputField
            extraClasses="transferPaymentFormInput"
            handleChange={(e: any) => setReason(e.target.value)}
            value={reason}
            label=""
            placeholder={t("reason")}
            type="text"
            name="reason"
          />
        </div>

        {/* payment amount divs */}
        <div className="mb-1">
          <label className="withdrawTitle">{t("enter_amount")}</label>
          <div className="flex ">
            <div
              className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
              style={{
                borderRadius: "10px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#e8e7e7",
                border: "1px solid #e8e8e8",
                borderRight: "none",
                marginBlock: "7px",
              }}
            >
              {t("EUR")}
            </div>
            <input
              name="Sent amount (EUR)"
              className="owner w-full text-sm"
              style={{
                background: "#fafafa",
                border: "none",
              }}
              placeholder={t("Sent_Amount_Eur_Only")}
              onChange={(e: any) => {
                setisCFAFirst(false);
                setLimitError(null);
                setErrorGot("");
                if (
                  Number(e.target.value) < 1 ||
                  Number(e.target.value) > Number(userBalance)
                ) {
                  if (paymentMethod === "balance") {
                    // toast.error(t("expectAmount") + userBalance);
                  }
                }
                if (checkCashInLimit(transfer, "min", e.target.value)) {
                  setLimitError(buildLimitError(transfer, "min", t));
                }
                if (checkCashInLimit(transfer, "max", e.target.value)) {
                  setLimitError(buildLimitError(transfer, "max", t));
                }
                setAmountInEuros(e.target.value);
                setAmountInCFA(
                  applyRate(e, spread_config.exchange_rate, "EUR")
                );
              }}
              value={amountInEuros}
              type="number"
              disabled={notReceivingCountry}
            />
          </div>
        </div>
        {notReceivingCountry && <Alert severity="error">{t(errorGot)}</Alert>}

        <div className="mb-3">
          <div className="flex">
            <div
              className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
              style={{
                borderRadius: "10px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#e8e7e7",
                border: "1px solid #e8e8e8",
                borderRight: "none",
                marginBlock: "7px",
              }}
            >
              {props.t("CFA")}
            </div>
            <input
              disabled={notReceivingCountry}
              className="owner w-full text-sm"
              style={{
                background: "#fafafa",
                border: "none",
              }}
              name="Amount received (CFA)"
              placeholder={props.t("Sent_Amount_Cfa_Only")}
              onChange={(e: any) => {
                props.setisCFAFirst(true);
                props.setAmountInCFA(e.target.value);
                props.setAmountInEuros(
                  applyRate(e, props.spread_config.exchange_rate, "CFA")
                );
              }}
              value={props.amountInCFA}
              type="number"
            />
          </div>
        </div>

        <div className="flex gap-3 max-sm:flex-col">
          <button
            onClick={props.handleBack}
            className="w-full rounded-lg btn btn-dark btn-block text-white capitalize"
          >
            {props.t("back")}
          </button>
          <button
            className="w-full rounded-lg btn btn-block text-white capitalize bg-[#037375]"
            disabled={fetchingFees || notReceivingCountry}
            onClick={validate}
          >
            {props.processingPayment
              ? props.t("processing")
              : props.t("continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AmountAndReason;
