import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";

import "../helpers/i18n";
import { getBankDetails } from "../store/features/Transfer/Transfer";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  addBeneficiary,
  openBulkTransferDialog,
  openDeposit,
  setBeneFound,
  setDepositData,
  setStep,
  setTLError,
  setTransactionToRepeat,
  setTransferDialogOpen,
  updateBulkTData,
} from "../store/features/Transfer/TransferSlice";
import CashLayout from "./CashLayout";
import { clearLocalStorageValues } from "../utilities/help";
import { CircularProgress } from "@material-ui/core";

const BankDetails: React.FC<any> = ({
  accountDetails,
  payment_id = "",
  amount = "",
  fees = 0,
  redirect = null,
  callback,
  widthdrawMethod,
  isDirectTransfer,
  mobileMoneyPhone,
  bankAccountIban,
  mfiAccountNumber,
  isDeposit = false,
}) => {
  const { lang, currency, rate } = useAppSelector(
    (state) => state.persistedReducer.auth
  );
  const { transfer } = useAppSelector((state) => state.persistedReducer);
  const { t } = useTranslation();
  const [details, setDetails] = React.useState<any>(null);
  const [amountAndFees, setAmountAndFees] = React.useState<number>(0);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isFetchingBankDetails, setisFetchingBankDetails] = useState(false);

  React.useEffect(() => {
    // setAmountAndFees(amount * rate + fees);
    setAmountAndFees(amount + fees);
    if (accountDetails !== null && accountDetails !== "") {
      try {
        setisFetchingBankDetails(true);
        getBankDetails(accountDetails.id, payment_id, amount * rate + fees)
          .then((res: any) => {
            callback && callback();
            setDetails(res.user_payment_details);
          })
          .catch((error: any) => {
            Sentry.captureException(error);
            toast.error(t(error?.data?.message));
            // toast.error(error?.data?.message);
          })
          .finally(() => setisFetchingBankDetails(false));
      } catch (error: any) {
        Sentry.captureException(error);
        setisFetchingBankDetails(false);
      }
    }
  }, []);

  return (
    <div className="w-full p-4 max-sm:!p-2 rounded-lg bankdetailsclass">
      <div className="mb-4 text-center">
        <h1 className="my-2 mb-4 md:text-lg lg:text-3xl font-bold">
          {t("bankDetailsTitle")}
        </h1>
        {lang === "en" && isDeposit ? (
          <p className="px-2 md:text-base text-sm">
            Your transaction will be finalized once you receive the funds.
            Please transfer{" "}
            <b>
              <CashLayout cash={amountAndFees} />
            </b>{" "}
            to our <b className="text-black">{details?.bank_name}</b> account.
          </p>
        ) : (
          <p className="px-2 text-left md:text-base text-sm">
            {t("bank_transfer_primary_info")}
            <br />
            <div className="my-4 font-bold" style={{ color: "#7f7f7f" }}>
              {t("bank_transfer_sub_info_1")}
              &nbsp;
              <b className="text-black underline">
                <CashLayout cash={amountAndFees} />
              </b>
              &nbsp;
              {t("bank_transfer_sub_info_2")}
              &nbsp;
              <b className="text-black underline">{details?.bank_name}</b>.
            </div>
          </p>
        )}
      </div>

      {/* bank details table */}
      {details == null && isFetchingBankDetails && (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
      {details && (
        <div className="w-full overflow-x-scroll">
          <table className="table">
            <tbody>
              <tr className="py-2">
                <th scope="row">
                  <small>{t("Reference")}</small>
                </th>
                <td>
                  <small>{details?.reference_number}</small>
                </td>
                <td className="text-right">
                  <span
                    className="p-2 px-4 text-gray-600 bg-gray-200 rounded-lg cursor-pointer badge"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.reference_number);
                      toast.dark("Reference number copied");
                    }}
                  >
                    {t("copy")}
                  </span>
                </td>
              </tr>
              <tr className="py-2">
                <th scope="row">
                  <small>{t("Bank_Name")}</small>
                </th>
                <td>
                  <small>{details?.bank_name}</small>
                </td>
                <td className="text-right">
                  <span
                    className="p-2 px-4 text-gray-600 bg-gray-200 rounded-lg cursor-pointer badge"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.bank_name);
                      toast.dark("bank name  copied");
                    }}
                  >
                    {t("copy")}
                  </span>
                </td>
              </tr>
              <tr className="py-2">
                <th scope="row">
                  <small>{t("name")}</small>
                </th>
                <td>
                  <small>{details?.owner_name}</small>
                </td>
                <td className="text-right">
                  <span
                    className="p-2 px-4 text-gray-600 bg-gray-200 rounded-lg cursor-pointer badge"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.owner_name);
                      toast.dark("Owner name  copied");
                    }}
                  >
                    {t("copy")}
                  </span>
                </td>
              </tr>
              <tr className="py-2">
                <th scope="row">
                  <small>{t("Address")}</small>
                </th>
                <td>
                  <small>{details?.country}</small>
                </td>
                <td className="text-right">
                  <span
                    className="p-2 px-4 text-gray-600 bg-gray-200 rounded-lg cursor-pointer badge"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.country);
                      toast.dark("Address copied");
                    }}
                  >
                    {t("copy")}
                  </span>
                </td>
              </tr>
              <tr className="py-2">
                <th scope="row">
                  <small>IBAN</small>
                </th>
                <td>
                  <small>{details?.iban}</small>
                </td>
                <td className="text-right">
                  <span
                    className="p-2 px-4 text-gray-600 bg-gray-200 rounded-lg cursor-pointer badge"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.iban);
                      toast.dark("IBAN copied");
                    }}
                  >
                    {t("copy")}
                  </span>
                </td>
              </tr>
              <tr className="py-2">
                <th scope="row">
                  <small>BIC</small>
                </th>
                <td>
                  <small>{details?.bic}</small>
                </td>
                <td className="text-right">
                  <span
                    className="p-2 px-4 text-gray-600 bg-gray-200 rounded-lg cursor-pointer badge"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.bic);
                      toast.dark("BIC copied");
                    }}
                  >
                    {t("copy")}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="flex flex-col items-center mt-8 justify-center">
        {/* danapay bank transfer */}
        {!isDeposit && (
          <div className="subdetails">
            {!isDirectTransfer && (
              <div className="px-2 md:text-base text-sm flex flex-col gap-3 mb-4">
                <div>
                  {t("danapay_bank_transfer_main_info_1")}
                  &nbsp;
                  <b className="underline">
                    {`+${transfer?.beneficiary?.country_code} ${transfer?.beneficiary?.phone_number}`}
                  </b>
                  &nbsp;
                  {t("danapay_bank_transfer_main_info_2")}
                  &nbsp;
                  <b className="underline">{transfer?.beneficiary?.email}</b>
                </div>
                <div className="instructions">
                  <p className="my-2 font-bold">
                    {t("danapay_bank_transfer_instruction")}
                  </p>
                  <ul className="list-disc">
                    <li
                      className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215906-acceder-a-son-compte-danapay-quand-on-a-recu-des-fonds",
                          "_blank"
                        )
                      }
                    >
                      {t("danapay_bank_transfer_instruction_1")}
                    </li>
                    <li
                      className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215915-retirer-ses-fonds-vers-un-compte-mobile",
                          "_blank"
                        )
                      }
                    >
                      {t("danapay_bank_transfer_instruction_2")}
                    </li>
                    <li
                      className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215918-retirer-ses-fonds-vers-un-compte-bancaire",
                          "_blank"
                        )
                      }
                    >
                      {t("danapay_bank_transfer_instruction_3")}
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {/* mobile money description */}
            {widthdrawMethod == "Mobile Money" && isDirectTransfer && (
              <p className="px-2 mb-4 mt-2 md:text-base text-sm">
                {t("mobile_money_bank_transfer_info_1")}{" "}
                <b className="text-black underline">{mobileMoneyPhone}</b>{" "}
                {t("mobile_money_bank_transfer_info_2")}
              </p>
            )}

            {/* bank account description */}
            {widthdrawMethod == "Bank Account" && isDirectTransfer && (
              <p className="px-2 mb-4 mt-2 md:text-base text-sm">
                {t("bank_account_bank_transfer_info_1")}{" "}
                <b className="text-black underline">{bankAccountIban}</b>{" "}
                {t("bank_account_bank_transfer_info_2")}
              </p>
            )}

            {/* micorfinance description */}
            {widthdrawMethod == "Microfinance" && isDirectTransfer && (
              <p className="px-2 mb-4 mt-2 md:text-base text-sm">
                {t("microfinance_bank_transfer_info_1")}{" "}
                <b className="text-black underline">{mfiAccountNumber}</b>{" "}
                {t("microfinance_bank_transfer_info_2")}
              </p>
            )}
          </div>
        )}

        <button
          className="mr-2 rounded-lg btn btn-block"
          style={{ backgroundColor: "rgb(3, 115, 117)" }}
          onClick={() => {
            clearLocalStorageValues([
              "full_phone_number",
              "mfiAccountNumber",
              "iban",
              "reciepentMobile",
              "appref",
              "withdrawMethod",
            ]);

            if (isDeposit) {
              dispatch(
                setDepositData({
                  amountInEURos: 0,
                  amountInCFA: 0,
                  paymentMethod: "",
                })
              );
            }

            if (redirect) {
              redirect();
            } else {
              dispatch(setBeneFound(false));
              dispatch(addBeneficiary(null));
              dispatch(setStep(0));
              dispatch(setTLError(null));
              dispatch(setTransactionToRepeat(null));
              dispatch(setTransferDialogOpen(false));
              dispatch(openBulkTransferDialog("close"));
              dispatch(openDeposit(false));
              dispatch(updateBulkTData({ users: {} }));
              history.push("/");
            }
          }}
        >
          <small className="font-bold text-white">{t("backHome")}</small>
        </button>
      </div>
    </div>
  );
};

export default BankDetails;
